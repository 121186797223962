import * as Sentry from '@sentry/browser';

const isProduction = process.env.STAGE === 'production';
const SENTRY_DSN = process.env.SENTRY_DSN;

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload(true);

if (isProduction && SENTRY_DSN) {
  try {
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 1.0,
    });
  } catch (err) {
    console.log(err);
  }
}
